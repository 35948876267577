@import 'animate.css';

$margin: 20px;
$border: 1px solid #ccc;
$stars: 5;
$stars-color:#FC0;
.bm {
/*category*/
.bm-category-info,
.bm-author-info {
    .image :not(.vd){
        float: left;
        max-width: 30%;
        margin-right: $margin;
        margin-bottom: $margin;
    }
    padding-bottom: 20px;
    margin-bottom: $margin;
}

.bm-categories {
    margin-bottom: $margin;
    padding-bottom: 20px;
    .item-cateogry {
        padding-bottom: 20px;
        display: block;
        .image {
            text-align: center;
            margin-bottom: 15px;
            img {
                width: 100%
            }
        }
        .title {
            text-align: center;
        }
    }
}

.bm-empty {
    padding: 15px 0px;
}


/*post_thum*/
.post-thumb{
    .image{
        position: relative;
        .categories {
            position: absolute;
            top: 0px;
            margin: 15px;
            li {
                display: inline-block;
            }
        }
    }
}

/*post*/
.bm-post-info {
    .category {
        margin-top: 15px;
        li {
            display: inline-block;
        }
    }
    .image {
        margin-top: 15px;
    }
    .btn-edit{
        position: absolute;
        right: 0px;
        margin-right: 15px;
    }
}

.image {}

.post-nav{
    margin-top:20px
}
.bm-author:after {
  content: "";
  display: table;
  clear: both;
}
/*review*/
.bm-reviews{
     .bm-pagination{
        margin-top: 20px;
     }

    .review {

        .media-left{
            .image {
                img {
                    border-radius: 100px;
                }
            }
        }
        .media-body{
            .description{
                min-height: 50px;
            }
        }
        .replies{
            .media-body{
                padding-left: 10%;
            }
            .media-left + .media-body{
                padding-left: 0px;
            }
            .rating{
                display: none
            }
            .media-left{
                img{
                    width: 30px
                }
            }
            .media-body{
                .description{
                    min-height: inherit;
                }
            }
        }
        .thumbnail{
            display: inline-block;
        }

    }
}
.form-review{
    .file-preview {
        border: none;
        padding: 0px;
        margin-bottom: 20px;
        .fileinput-remove{
            line-height: 0.8;
            padding: 7px;
        }
        .file-drop-zone {
            margin: 0px;
            padding: 0px;
        }
    }
    .rating {
       height: 34px;
    }

    #input_cancel_reply{
        .name{
            width: 100%
        }
    }

    .buttons{
        margin: 0px;
    }

    .customer-info {
        &:before {
            content: "";
            display: inline-block;
            vertical-align: middle;
            height: 100%;
        }
        .image {
            img {
                border-radius: 17px;
                width: 34px;
                vertical-align: middle;
                display: inline-block;
                margin-right: 15px;
            }
        }
        .name {
            display: inline-block;
            vertical-align: middle;
            font-size: 14px;
        }
    }
    .file-drop-zone-title{
        padding: 30px 10px;
    }
}

/*fix*/
.fa-2x,
.fa-stack-2x {
    font-size: 1.5rem !important;
}

/*elements*/
    .rating[class*="rating-"]{
        .fa-stack {
            font-size: 7px;
            .fa-star {
                display: none;
            }
        }
    }
.rating {
    @for $star from 1 through $stars {
        &.rating-#{$star} {
            .fa-stack:nth-child(-n+#{$star}) {
                .fa-star {
                    display: block
                }
            }
        }
    }
}

@media (min-width:1200px){
    .auto-clear .col-lg-1:nth-child(12n+1){clear:left;}
    .auto-clear .col-lg-2:nth-child(6n+1){clear:left;}
    .auto-clear .col-lg-3:nth-child(4n+1){clear:left;}
    .auto-clear .col-lg-4:nth-child(3n+1){clear:left;}
    .auto-clear .col-lg-6:nth-child(odd){clear:left;}
}
@media (min-width:992px) and (max-width:1199px){
    .auto-clear .col-md-1:nth-child(12n+1){clear:left;}
    .auto-clear .col-md-2:nth-child(6n+1){clear:left;}
    .auto-clear .col-md-3:nth-child(4n+1){clear:left;}
    .auto-clear .col-md-4:nth-child(3n+1){clear:left;}
    .auto-clear .col-md-6:nth-child(odd){clear:left;}
}
@media (min-width:768px) and (max-width:991px){
    .auto-clear .col-sm-1:nth-child(12n+1){clear:left;}
    .auto-clear .col-sm-2:nth-child(6n+1){clear:left;}
    .auto-clear .col-sm-3:nth-child(4n+1){clear:left;}
    .auto-clear .col-sm-4:nth-child(3n+1){clear:left;}
    .auto-clear .col-sm-6:nth-child(odd){clear:left;}
}
@media (max-width:767px){
    .auto-clear .col-xs-1:nth-child(12n+1){clear:left;}
    .auto-clear .col-xs-2:nth-child(6n+1){clear:left;}
    .auto-clear .col-xs-3:nth-child(4n+1){clear:left;}
    .auto-clear .col-xs-4:nth-child(3n+1){clear:left;}
    .auto-clear .col-xs-6:nth-child(odd){clear:left;}
}
}
//07-30
//blog and vd 3 issue icons 5 pro
.content.vd .bm [class*=col-]{
    padding: 0 15px !important;
}
.bm .fa-stack-1x, .fa-stack-2x{
    position: relative;
}
.bm .rating .fa.fa-star-o{
    color: #ffa600;
}
.rating-symbol-background.fa.fa-star-o.fa-stack-1x.empty{
visibility: visible !important;   
z-index: 2; 
}